import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './signin.vue'

Vue.config.productionTip = false

require('@/pages/page-modules.js');

/* eslint-disable no-new */
new Vue({
    vuetify,
    render: h => h(App),
}).$mount('#app')