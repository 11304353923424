<template>
    <div class="card">
        <div class="card-header mx-auto">
            <div class="w-100 text-center">
                <img class="img-fluid logo-login company-logo pt-10 pb-10" :src="companyLogoImgPath"/>
            </div>
        </div>
        <div class="card-content collapse show" aria-expanded="true">
            <!-- You get to insert code here to login available. -->
            <div class="card-body">
                <v-form
                    v-on:submit.stop.prevent="" ref="ref-form">
                    <div>
                        <div class="g-recaptcha pt-0 pb-1 d-flex flex-wrap justify-center" ref="ref_recaptcha" :data-sitekey="sitekey"></div>
                    </div>
                    <div class="form-group">
                        <div id='buttonDiv' class="pt-4 pb-1 d-flex flex-wrap justify-center"></div>
                    </div>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
var pageCache = new APP_SVC.PageCache();
export default {
       data: function(){
        return {
            loginId: '',
            companyLogoImgPath: process.env.BASE_URL + 'static/sti/images/logo.png',
            companyName: 'AnyonePay',
            recaptcha: "",
            sitekey : typeof window.RECAPTCHA_SITE_KEY !== 'undefined' ? window.RECAPTCHA_SITE_KEY : '',
        }
    },
    created: function () {
    },
    mounted: function () {
        this.loadGoogleApi();
        this.setRecaptchaJs();
    },
    methods: {
        setRecaptchaJs : function(){
            var scriptEl = document.createElement('script');
            scriptEl.src = "//www.google.com/recaptcha/api.js";
            document.head.appendChild(scriptEl);
        },
        checkReCaptcha : function(){
            const vue = this;

            if( typeof grecaptcha === 'undefined' ){
                return;
            }

            const reCaptchaKey = window.grecaptcha.getResponse();
            if( reCaptchaKey.length > 0 ) return reCaptchaKey;
                Swal.fire({
                    title: 'Please check Captcha first.',
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            return;
        },
        loadGoogleApi(){
            const self = this;

            const ele = document.createElement("script");
            ele.src = 'https://accounts.google.com/gsi/client';
            ele.onload = ()=>{
                // Document : https://developers.google.com/identity/gsi/web/guides/display-button#javascript
                google.accounts.id.initialize({
                    client_id: '375488954254-5gl7cp6f7nv1o5bn7qe4g5jvpj60c78v.apps.googleusercontent.com',
                    callback: (response) => {
                        console.warn("Encoded JWT ID token ", response.credential);
                        console.log("google response", response);
                        self.onClickLogin(response.credential);
                    }
                });
                google.accounts.id.renderButton(
                    document.getElementById("buttonDiv"),
                    { theme: "outline", size: "large" }  // customization attributes
                );
                google.accounts.id.prompt(); // also display the One Tap dialog
            };
            document.getElementsByTagName("head")[0].appendChild(ele);
        },
        onClickLogin(gToken){

            if( ! gToken ){
                alert("Please login with Google Account.");
                return;
            }

            const vue = this;

            // Check ReCaptcha
            const reCaptcha = this.checkReCaptcha();
            if( ! reCaptcha ){
                return;
            }

            APP_SVC.Ajax.request({
                method: 'post',
                url: APP_APISERVER.uri("post-signin").asString(),
                data: {
                    gOauthToken: gToken,
                    reCaptcha: reCaptcha
                }
            }).then(function () {
                var router = new RouteUtil("/index.html");
                router.go();
            }).catch(function (err) {
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            }).finally(function () {
            });
        },
    } 
}
</script>

<style scoped>
    .g-recaptcha {
        margin:0px auto 0px auto;
        width: 300px;
    }
</style>
