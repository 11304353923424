<template>
    <v-app class="vh-100">
        <div class="content ml-0">
            <div class="content-wrapper mt-0">
                <!-- HEADER START -------------------------------------- -->
                <!-- HEADER END ---------------------------------------- -->
                <!-- CONTENT START ------------------------------------- -->
                <div class="content-body">
                    <section>
                        <div class="row justify-content-center">
                            <div class="col-xs-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                <!-- Called Template inside this HTML START- -->
                                <div is="content-area"></div>
                                <!-- Called Template inside this HTML END -- -->
                            </div>
                        </div>
                    </section>
                </div>
                <!-- CONTENT END --------------------------------------- -->
            </div>
        </div>
        <!-- END: Content -->
    </v-app>
</template>
<script>

import ContentAreaComponent from './signin-content.vue';

export default {
    name: "SignIn",
    components: {
        'content-area' : ContentAreaComponent
    },
    data() {
        return {
            pageName : 'SignIn',
            subjectCompanyName: 'Stic',
        }
    },
    mounted() {
    }
}
</script>
<style scoped>

</style>